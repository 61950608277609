<template>
  <div>
    <grid
      item-min-width="300px"
      class="align-center"
    >
      <component
        class="grid-component"
        :is="computedDataType"
        :document.sync="computedParams"
        :view-type="viewType"
        :dense="false"
        v-on="$listeners"
        ref="component"
      />
    </grid>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    Grid: () => import('@/components/grid'),
    AgingEntityParams: () => import('./aging-entity-params'),
    AgingAccountParams: () => import('./aging-account-params')
  },
  computed: {
    computedParams: {
      get () {
        return this.dataParams
      },
      set (v) {
        this.dataParams = v
        this.dataDocumentEmit()
      }
    },
    computedDataType () {
      return this.viewType === 'accounts' ? 'AgingAccountParams' : 'AgingEntityParams'
    }
  },
  data () {
    return {
      dataParams: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.document, this.dataParams)) {
        this.$emit('update:document', this.lodash.cloneDeep(this.dataParams))
      }
    }
  },
  props: {
    document: Object,
    viewType: String,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataParams = v ? this.lodash.cloneDeep(v) : { ty: null, c: null }
        this.dataDocumentEmit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.grid-component
  display contents
</style>
